import { useMemo } from 'react';
import { Box, Button, Textarea, VStack } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { GroupController, Radio } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const otherCountry =
  'Votre société est-elle résidente fiscale dans un autre pays ?';
const residenceFiscalNif =
  'Pour vos autres résidences fiscales, renseignez les NIF';
const revenue =
  'Faites-vous plus de 95% de votre chiffre d’affaire dans l’Union Européenne ?';
const revenueOutsideUE =
  'Renseignez le pourcentage de votre chiffre d’affaires réalisé hors Union Européenne';

const validationSchema = z
  .object({
    [otherCountry]: z.union([z.literal('Yes'), z.literal('No')]),
    [residenceFiscalNif]: z.string(),
    [revenue]: z.union([z.literal('Yes'), z.literal('No')]),
    [revenueOutsideUE]: z.string(),
  })
  .superRefine((values, context) => {
    if (values[otherCountry] && values[otherCountry] === 'Yes') {
      if (values[residenceFiscalNif] === '') {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please complete this mandatory field.',
          path: [residenceFiscalNif],
        });
      }
    }
    if (values[revenue] && values[revenue] === 'No') {
      if (values[revenueOutsideUE] === '') {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please complete this mandatory field.',
          path: [revenueOutsideUE],
        });
      }
    }
  });

export const TaxResidence = () => {
  const stepId = useStepId();

  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [otherCountry]: metadata?.[otherCountry] || '',
      [residenceFiscalNif]: metadata?.[residenceFiscalNif] || '',
      [revenue]: metadata?.[revenue] || '',
      [revenueOutsideUE]: metadata?.[revenueOutsideUE] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: zodResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={otherCountry}
          label={t(`steps.${stepId}.${otherCountry}.label`)}
          isRequired={true}
          helper={t(`steps.${stepId}.${otherCountry}.helper`)}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={otherCountry}
              onChange={(value: string) => {
                setValue(otherCountry, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />
        {watch(otherCountry) === 'Yes' && (
          <GroupController
            name={residenceFiscalNif}
            label={t(`steps.${stepId}.${residenceFiscalNif}.label`)}
            isRequired={true}
            helper={t(`steps.${stepId}.${residenceFiscalNif}.helper`)}
            control={control}
            render={(f) => {
              return (
                <Textarea
                  maxW="400px"
                  resize="vertical"
                  placeholder={t(
                    `steps.${stepId}.${residenceFiscalNif}.placeholder`,
                  )}
                  {...f}
                />
              );
            }}
          />
        )}
        <GroupController
          name={revenue}
          label={t(`steps.${stepId}.${revenue}.label`)}
          isRequired={true}
          helper={t(`steps.${stepId}.${revenue}.helper`)}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={revenue}
              onChange={(value: string) => {
                setValue(revenue, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />
        {watch(revenue) === 'No' && (
          <GroupController
            name={revenueOutsideUE}
            label={t(`steps.${stepId}.${revenueOutsideUE}.label`)}
            isRequired={true}
            control={control}
            render={(f) => {
              return (
                <Textarea
                  maxW="400px"
                  resize="vertical"
                  placeholder={t(
                    `steps.${stepId}.${revenueOutsideUE}.placeholder`,
                  )}
                  {...f}
                />
              );
            }}
          />
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
