import {
  Action,
  State,
  AppConfig,
  Environment,
  DocumentAnalysisModelEnum,
  DocumentTypeEnum,
} from 'shared-domain';

import { environment } from '../environments/environment';
import { companyFields } from './company';
import { affiliatedCompanyFields } from './affiliated-company';
import { individualFields } from './individual';
import { stepsConfig } from './step';

const beforePreviousStep = ({
  currentStep,
  metadata,
  decreaseCurrentStep,
  updateCurrentStep,
}: Pick<
  State & Action,
  'currentStep' | 'metadata' | 'decreaseCurrentStep' | 'updateCurrentStep'
>): void => {
  if (
    stepsConfig[currentStep - 1].id === 'tax_residence_tmp_panto' &&
    !metadata['A qui vendez vous ?']
  ) {
    updateCurrentStep(5);
  } else {
    decreaseCurrentStep();
  }
};

export const bootstrapConfig: Partial<Omit<AppConfig, 'environment'>> & {
  environment: Environment;
} = {
  websiteTitle: '1POINT6',
  showPoweredLogo: false,
  languages: ['fr'],
  companyFields,
  affiliatedCompanyFields,
  individualFields,
  stepsConfig,
  environment,
  documentAnalysisConfig: {
    [`document_type:${DocumentTypeEnum.registration_certificate}`]: (
      store: State,
    ) => ({
      automatic_approval: false,
      automatic_rejection: false,
      parameters: {
        model: DocumentAnalysisModelEnum.registration_certificate,
        max_age_in_days: 90,
      },
    }),
  },

  beforePreviousStep,
};
