import { useEffect, useMemo } from 'react';
import { Box, Button, Textarea, VStack, Text } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, GroupController } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const sell = 'A qui vendez vous ?';
const sell_options = ['A des professionnels', 'A des particuliers'];
const marchandise =
  'Renseignez tous les types de marchandises que vous vendez et leurs origines';
const paymentMethods =
  'Renseignez tous les moyens de paiement que vous acceptez';

const codeActivities = [
  '47.79Z',
  '45.20A',
  '45.20B',
  '45.40Z',
  '47.41Z',
  '47.41Z',
  '47.43Z',
  '47.79Z',
  '33.13Z',
  '26.30Z',
  '35.11Z',
  '56.30Z',
  '56.10C',
  '59.13A',
  '59.14Z',
  '4779Z',
  '4520A',
  '4520B',
  '4540Z',
  '4741Z',
  '4741Z',
  '4743Z',
  '4779Z',
  '3313Z',
  '2630Z',
  '3511Z',
  '5630Z',
  '5610C',
  '5913A',
  '5914Z',
  '4779',
  '4520',
  '4520',
  '4741',
  '4742',
  '4743',
  '4779',
  '9511',
  '9512',
  '3511',
  '5630',
  '5610',
  '5913',
  '5914',
];

export const CompanyActivity = () => {
  const stepId = useStepId();

  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata, company, updateMetadata, updateCurrentStep } = useStore();

  const validationSchema = Yup.object({
    [sell]: Yup.array(Yup.mixed().oneOf(sell_options)).min(1).required(),
    [marchandise]: Yup.string().required(),
    [paymentMethods]: Yup.string().required(),
  });

  const defaultValues = useMemo(() => {
    return {
      [sell]: metadata?.[sell] || '',
      [marchandise]: metadata?.[marchandise] || '',
      [paymentMethods]: metadata?.[paymentMethods] || '',
    };
  }, [metadata]);

  useEffect(() => {
    if (company?.classifications && company.classifications.length > 0) {
      if (!codeActivities.includes(company.classifications[0].code)) {
        delete metadata[sell];
        delete metadata[marchandise];
        delete metadata[paymentMethods];
        updateMetadata({ ...metadata });
        updateCurrentStep(7);
      }
    }
  }, [company?.classifications]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={sell}
          label={t(`steps.${stepId}.${sell}.label`)}
          control={control}
          render={(f) => (
            <Checkbox
              stepId={stepId}
              name={sell}
              onChange={(values: string[]) => {
                setValue(sell, values ?? [], {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={sell_options}
              defaultValue={f.value}
            />
          )}
        />
        <GroupController
          name={marchandise}
          label={t(`steps.${stepId}.${marchandise}.label`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return (
              <Textarea
                maxW="400px"
                resize="vertical"
                placeholder={t(`steps.${stepId}.${marchandise}.placeholder`)}
                {...f}
              />
            );
          }}
        />
        <GroupController
          name={paymentMethods}
          label={t(`steps.${stepId}.${paymentMethods}.label`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return (
              <Textarea
                maxW="400px"
                resize="vertical"
                placeholder={t(`steps.${stepId}.${paymentMethods}.placeholder`)}
                {...f}
              />
            );
          }}
        />

        <Text>{t(`steps.${stepId}.custom_text`)}</Text>

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
