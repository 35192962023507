import {
  BaseStepIdEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';
import { CompanyActivity } from '../app/components/company-activity';
import { TaxResidence } from '../app/components/tax-residence';

// /!\ Do not add/remove a step. In this case, change the updateCurrentStep param in:
//  - main.tsx (app)
//  - company-activity.tsx (app)
export const stepsConfig: StepConfig[] = [
  {
    id: 'contact_information',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'Phone number',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.tel,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Contact email',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.email,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'company_bank',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'iban',
          nested: 'banking_information',
          type: FieldTypeEnum.iban,
          isRequired: true,
          isEnabled: true,
          hasHelper: false,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'bic',
          nested: 'banking_information',
          type: FieldTypeEnum.bic,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
      ],
    },
  },
  {
    id: 'company_activity_tmp_panto',
    type: StepTypeEnum.custom_component,
    component: CompanyActivity,
  },
  {
    // @TODO - TBD - Do not rename the step because we use it for a custom code in the lib (app.tsx)
    id: 'tax_residence_tmp_panto',
    type: StepTypeEnum.custom_component,
    component: TaxResidence,
  },
  {
    id: 'activity_abroad',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'Confirmez que',
          type: FieldTypeEnum.checkbox,
          options: [
            "La société n'est pas une US person",
            "La société n'est pas une entité exemptée",
            "La société n'est pas une ENF passive",
            "La société n'est pas une institution financière",
          ],
          isRequired: true,
          isEnabled: true,
          hasHelper: false,
          propertyType: FieldPropertyTypeEnum.metadata,
          forceAllChecked: true,
        },
      ],
    },
  },
  {
    id: 'pdf_viewer_activity_in_countries_risked',
    type: StepTypeEnum.terms_and_conditions,
    config: { pdfUrl: '' },
  },
  {
    id: BaseStepIdEnum.affiliated_companies_list,
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: false },
  },
  {
    id: 'pdf_viewer_terms',
    type: StepTypeEnum.terms_and_conditions,
    config: {
      pdfUrl:
        'https://getpanto.onboarding.dotfile.com/assets/terms-and-conditions.pdf',
    },
  },
];
