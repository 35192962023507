const brandColors = {
  primary: '#2f2926',
  secondary: '#2f2926',
};

const sidebarColors = {
  backgroundColor: '#2f2926',
  color: '#faf9f9',
  dotfileLogo: '#faf9f9',
};

const linkColors = {
  color: '#2F6CE1',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
